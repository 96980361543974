@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css");
@import url("https://use.fontawesome.com/releases/v5.3.1/css/all.css");
@import "~@angular/material/prebuilt-themes/purple-green.css";
/*
use max-height or min-height for map
*/

html,
body {
    overflow: hidden;
    height: 100%;
    background-color: grey;
}

body {
    position: relative;
    margin: 0;
    padding: 0;
    overflow: auto;
    width: 100%;
    background-color: grey;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.main-view {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.component-container {
    margin-top: 2%;
    width: 100%;
}

ul {
    list-style-type: none;
}

@media (min-width: 1400px) {
    .form-container {
        width: 66%;
        margin: auto;
    }

    .content-holder {
        width: 45%;
        height: 300px;
        margin: auto;
        text-align: center;
    }

    .content-form {
        position: relative;
        top: 33%;
    }

    .form-holder {
        height: 520px;
        margin: auto;
        text-align: center;
        width: 45%;
    }

    .mat-form-field {
        display: block;
        width: 100%;
    }
}

@media (max-width: 1400px) {
    .form-container {
        width: 66%;
        margin: auto;
    }

    .content-holder {
        width: 45%;
        height: 300px;
        margin: auto;
        text-align: center;
    }

    .content-form {
        position: relative;
        top: 33%;
    }

    .form-holder {
        height: 520px;
        margin: auto;
        text-align: center;
        width: 45%;
    }

    .mat-form-field {
        display: block;
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .content-container {
        width: 90%;
    }

    .content-holder {
        width: 80%;
    }

    .form-holder {
        width: 90%;
    }

    @media (max-width: 768px) {
        .content-container {
            width: 90%;
        }

        .content-holder {
            width: 95%;
        }

        .form-container {
            width: 90%;
        }
    }
}